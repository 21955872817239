<template>
    <div>
        <div class="d-flex justify-content-center mt-n4 mt-md-n0">
            <div
                data-aos="fade-zoom-in"
                data-aos-delay="1100"
                data-aos-offset="0"
                class="introduction__logo__card bg-site-white d-flex flex-column justify-content-center align-items-center"
            >
                <p data-aos="fade-down" data-aos-duration="1900" class="introduction__logo__card-btn bg-site-primary text-white border-0">Introductie</p>
                <h1 data-aos="fade-up" data-aos-duration="1900" class="introduction__logo__card-text text-site-primary text-center pt-lg-5 pt-2">
                    De weg naar
                    <span class="font-italic mr-2">jouw</span>
                    <br />
                    nieuwe klanten.
                </h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IntroCard',
};
</script>

<style></style>
