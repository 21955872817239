<template>
    <b-container fluid="xl">
        <!-- <div id="positionLocator">{{ scrollY }}</div> -->
        <!-- <div id="positionLocator">{{ innerWidth }}</div> -->

        <div class="position-relative">
            <div class="roadMap" :class="scrollY >= 300 ? '' : 'initialRoadMap'" />

            <div v-if="innerWidth > 1150">
                <div v-if="!isMobileScreen" id="arrow" :style="arrowStyles" :class="scrollY >= 300 ? 'd-block' : 'd-none'">
                    <img class="fade-transition" src="../assets/images/Icon.svg" />
                </div>
            </div>
            <div v-if="isMobileScreen" id="arrowSm" :class="scrollY >= 400 && scrollY <= calculatedScrollHeight ? 'd-block' : 'd-none'">
                <img src="../assets/images/Icon.svg" />
            </div>
            <svg
                data-aos="zoom-in"
                data-aos-easing="linear"
                data-aos-duration="500"
                class="d-flex mx-auto mt-3"
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
            >
                <circle cx="8.87836" cy="8.87836" r="8.87836" transform="matrix(-1 0 0 1 18.423 0)" fill="#211D4E" />
            </svg>
            <!-- <div class="roadMap" ref="roadMap" /> -->
            <Introduction />
            <MarketingDesign />
            <MarketingCampaign />
            <PromotionPhase />
        </div>
        <ContactUs />
        <Footer />
    </b-container>
</template>

<script>
import Introduction from '@/components/Introduction.vue';
import MarketingDesign from '@/components/MarketingDesign.vue';
import MarketingCampaign from '@/components/MarketingCampaign.vue';
import PromotionPhase from '@/components/PromotionPhase.vue';
import ContactUs from '@/components/ContactUs.vue';
import Footer from '@/components/Footer.vue';

export default {
    name: 'Home',
    components: {
        Introduction,
        MarketingDesign,
        MarketingCampaign,
        PromotionPhase,
        ContactUs,
        Footer,
    },
    data() {
        return {
            scrollY: 0,
            scrollHeight: 0,
            calculatedScrollHeight: 0,
            innerWidth: 0,
            showArrow: false,
        };
    },
    methods: {
        handleScroll() {
            this.scrollY = window.scrollY;
        },
        handleWidth() {
            this.innerWidth = window.innerWidth;
        },
        handleHeight() {
            this.scrollHeight = document.body.scrollHeight;
            if (this.innerWidth < 400) {
                this.calculatedScrollHeight = (document.body.scrollHeight / 100) * 88.5;
            } else {
                this.calculatedScrollHeight = (document.body.scrollHeight / 100) * 87.5;
            }
        },
        calculateArrowPosition() {
            let ranges = [];
            const innerWidth = this.innerWidth;
            if (innerWidth < 1300) {
                ranges = [
                    { min: 550, max: 700.99, initialLeft: 49.04, finalLeft: 49.04 },
                    { min: 701, max: 715, initialLeft: 49.04, finalLeft: 4.4 },
                    { min: 715, max: 1873, initialLeft: 4.4, finalLeft: 4.4 },
                    { min: 1873, max: 1885, initialLeft: 4.4, finalLeft: 93.2 },
                    { min: 1885, max: 3303, initialLeft: 93.2, finalLeft: 93.2 },
                    { min: 3303, max: 3320, initialLeft: 93.2, finalLeft: 4.4 },
                    { min: 3320, max: 4593, initialLeft: 4.4, finalLeft: 4.4 },
                    { min: 4593, max: 4603, initialLeft: 4.4, finalLeft: 92.15 },
                    { min: 4603, max: 5910, initialLeft: 92.15, finalLeft: 92.15 },
                    { min: 5910, max: 5920, initialLeft: 92.15, finalLeft: 5.25 },
                    { min: 5920, max: 6176, initialLeft: 5.25, finalLeft: 5.25 },
                    { min: 6176, max: 6185, initialLeft: 5.25, finalLeft: 92.05 },
                    { min: 6185, max: 7010, initialLeft: 92.05, finalLeft: 92.05 },
                    { min: 7010, max: 7022, initialLeft: 92.05, finalLeft: 3.65 },
                    { min: 7022, max: 7508, initialLeft: 3.65, finalLeft: 3.65 },
                    { min: 7508, max: 7520, initialLeft: 8, finalLeft: 8 },
                ];
            } else if (innerWidth < 1400) {
                ranges = [
                    { min: 550, max: 700.99, initialLeft: 49.14, finalLeft: 49.14 },
                    { min: 701, max: 715, initialLeft: 49.14, finalLeft: 4.5 },
                    { min: 715, max: 1873, initialLeft: 4.5, finalLeft: 4.5 },
                    { min: 1873, max: 1885, initialLeft: 4.5, finalLeft: 93.4 },
                    { min: 1885, max: 3303, initialLeft: 93.4, finalLeft: 93.4 },
                    { min: 3303, max: 3320, initialLeft: 93.4, finalLeft: 4.66 },
                    { min: 3320, max: 4593, initialLeft: 4.66, finalLeft: 4.66 },
                    { min: 4593, max: 4603, initialLeft: 4.66, finalLeft: 92.4 },
                    { min: 4603, max: 5910, initialLeft: 92.4, finalLeft: 92.4 },
                    { min: 5910, max: 5920, initialLeft: 92.4, finalLeft: 5.4 },
                    { min: 5920, max: 6176, initialLeft: 5.4, finalLeft: 5.4 },
                    { min: 6176, max: 6185, initialLeft: 5.4, finalLeft: 92.2 },
                    { min: 6185, max: 7010, initialLeft: 92.2, finalLeft: 92.2 },
                    { min: 7010, max: 7022, initialLeft: 92.2, finalLeft: 3.8 },
                    { min: 7022, max: 7508, initialLeft: 3.8, finalLeft: 3.8 },
                    { min: 7508, max: 7520, initialLeft: 8, finalLeft: 8 },
                ];
            } else if (innerWidth <= 1500) {
                ranges = [
                    { min: 550, max: 700.99, initialLeft: 49.24, finalLeft: 49.24 },
                    { min: 701, max: 715, initialLeft: 49.24, finalLeft: 4.6 },
                    { min: 715, max: 1873, initialLeft: 4.6, finalLeft: 4.6 },
                    { min: 1873, max: 1885, initialLeft: 4.6, finalLeft: 93.5 },
                    { min: 1885, max: 3303, initialLeft: 93.5, finalLeft: 93.5 },
                    { min: 3303, max: 3320, initialLeft: 93.5, finalLeft: 4.7 },
                    { min: 3320, max: 4593, initialLeft: 4.7, finalLeft: 4.7 },
                    { min: 4593, max: 4603, initialLeft: 4.7, finalLeft: 92.5 },
                    { min: 4603, max: 5910, initialLeft: 92.5, finalLeft: 92.5 },
                    { min: 5910, max: 5920, initialLeft: 92.5, finalLeft: 5.4 },
                    { min: 5920, max: 6176, initialLeft: 5.4, finalLeft: 5.4 },
                    { min: 6176, max: 6185, initialLeft: 5.4, finalLeft: 92.3 },
                    { min: 6185, max: 7010, initialLeft: 92.3, finalLeft: 92.3 },
                    { min: 7010, max: 7022, initialLeft: 92.3, finalLeft: 3.9 },
                    { min: 7022, max: 7508, initialLeft: 3.9, finalLeft: 3.9 },
                    { min: 7508, max: 7520, initialLeft: 8, finalLeft: 8 },
                ];
            } else if (innerWidth > 1500) {
                ranges = [
                    { min: 550, max: 700.99, initialLeft: 49.34, finalLeft: 49.34 },
                    { min: 701, max: 715, initialLeft: 49.34, finalLeft: 4.7 },
                    { min: 715, max: 1873, initialLeft: 4.7, finalLeft: 4.7 },
                    { min: 1873, max: 1885, initialLeft: 4.7, finalLeft: 93.6 },
                    { min: 1885, max: 3303, initialLeft: 93.6, finalLeft: 93.6 },
                    { min: 3303, max: 3320, initialLeft: 93.6, finalLeft: 4.9 },
                    { min: 3320, max: 4593, initialLeft: 4.8, finalLeft: 4.8 },
                    { min: 4593, max: 4603, initialLeft: 4.9, finalLeft: 92.458 },
                    { min: 4603, max: 5910, initialLeft: 92.58, finalLeft: 92.58 },
                    { min: 5910, max: 5920, initialLeft: 92.58, finalLeft: 5.6 },
                    { min: 5920, max: 6176, initialLeft: 5.6, finalLeft: 5.6 },
                    { min: 6176, max: 6185, initialLeft: 5.6, finalLeft: 92.4 },
                    { min: 6185, max: 7010, initialLeft: 92.4, finalLeft: 92.4 },
                    { min: 7010, max: 7022, initialLeft: 92.4, finalLeft: 4 },
                    { min: 7022, max: 7508, initialLeft: 4, finalLeft: 4 },
                    { min: 7508, max: 7520, initialLeft: 8, finalLeft: 8 },
                ];
            }

            for (const range of ranges) {
                if (this.scrollY >= range.min && this.scrollY <= range.max) {
                    const left = range.initialLeft - ((range.initialLeft - range.finalLeft) * (this.scrollY - range.min)) / 35;
                    return { left: `${left}%`, top: `${this.scrollY + 350}px` };
                }
                if (this.scrollY < 550) {
                    if (this.innerWidth < 1325) {
                        return { left: `49.04%`, top: `900px` };
                    } else {
                        return { left: `49.34%`, top: `900px` };
                    }
                }
                if (this.scrollY >= 7520) {
                    return { left: `8%`, top: `7869px` };
                }
            }

            return { left: '49.34%', top: `${this.scrollY + 350}px` };
        },

        calculateArrowRotation() {
            const rotationRanges = [
                { minScrollY: 701, maxScrollY: 715, rotation: 'rotate(90deg)' },
                { minScrollY: 1873, maxScrollY: 1886, rotation: 'rotate(-90deg)' },
                { minScrollY: 3303, maxScrollY: 3321, rotation: 'rotate(90deg)' },
                { minScrollY: 4593, maxScrollY: 4604, rotation: 'rotate(-90deg)' },
                { minScrollY: 5910, maxScrollY: 5921, rotation: 'rotate(90deg)' },
                { minScrollY: 6176, maxScrollY: 6186, rotation: 'rotate(-90deg)' },
                { minScrollY: 7010, maxScrollY: 7023, rotation: 'rotate(90deg)' },
                { minScrollY: 7508, maxScrollY: Infinity, rotation: 'rotate(-90deg)' },
            ];

            for (const range of rotationRanges) {
                if (this.scrollY >= range.minScrollY && this.scrollY <= range.maxScrollY) {
                    return range.rotation;
                }
            }

            return 'rotate(0deg)';
        },
        calculateArrowShowHide() {
            if (this.scrollY >= 500 && this.scrollY <= 7700) {
                this.showArrow = true;
            } else {
                this.showArrow = false;
            }
        },
    },
    computed: {
        arrowStyles() {
            let arrowPosition = this.calculateArrowPosition();
            this.calculateArrowShowHide();
            return {
                transform: this.calculateArrowRotation(),
                left: arrowPosition.left,
                top: arrowPosition.top,
            };
        },
        isMobileScreen() {
            if (this.innerWidth < 768) {
                return true;
            } else {
                return false;
            }
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('scroll', this.handleHeight);
        window.addEventListener('scroll', this.handleWidth);
    },
};
</script>

<style lang="scss">
.roadMap {
    background-image: url(../assets/images/roadMap.png);
    background-repeat: no-repeat;

    @media screen and (min-width: 767.98px) and (max-width: 1150px) {
        display: none;
    }
    @media (max-width: 767.98px) {
        background-image: url(../assets/images/mobile1.png) !important;
        background-size: auto !important;
        top: 470px !important;
        height: 97.5% !important;
    }
    @media screen and (min-width: 400px) and (max-width: 600px) {
        // height: 94.5% !important;
    }
}
.initialRoadMap {
    top: 0 !important;
    opacity: 0.001;
}

#arrow {
    position: absolute;
    z-index: 9999999999;
}
#arrowSm {
    position: fixed;
    top: 200px;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
}

#positionLocator {
    width: 120px;
    position: fixed;
    right: 30px;
    top: 30px;
    background: red;
    min-height: 100px;
    z-index: 9999999999;
}
.fade-transition {
    animation: fadeIn 1s;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
