<template>
    <div class="mt-5">
        <div class="d-flex justify-content-center card-wrapper w-100">
            <div
                class="container card-bg"
                :class="[
                    data.number == 5 ? 'fase5' : 'max-h-480',
                    data.number == 1 ? 'bg-1' : data.number == 2 ? 'bg-2' : data.number == 3 ? 'bg-3' : data.number == 4 ? 'bg-4' : '',
                ]"
            >
                <b-container class="text-white px-5">
                    <div class="max-w-80">
                        <p class="text-site-white mt-5 d-none d-md-flex">Einde fase {{ data.number }}</p>
                        <h1 class="marketingCard__card-title text-site-white text-start p3-5">
                            {{ data.title }}
                        </h1>
                        <hr class="marketingCard__card-line bg-site-orange w-50 my-lg-4 mx-0" />
                        <p class="marketingCard__card-text text-site-white pb-4">
                            <span class="marketingCard__card-text-heading">{{ data.heading }}</span>
                            <br />
                            <span class="font-weight-bold" v-if="data.heading1">{{ data.heading1 }}</span>
                            {{ data.description }}
                        </p>
                        <ul class="text-site-white" v-if="data.listItem">
                            <li class="marketingCard__card-text" v-for="(item, index) in data.listItem" :key="index">
                                {{ item.text }}
                            </li>
                        </ul>
                    </div>
                    <img :src="r_logo" class="r_logo" />
                </b-container>
            </div>
        </div>
    </div>
</template>

<script>
import dena from '../assets/images/dena.png';
import rafael from '../assets/images/rafael.png';
import kirsten from '../assets/images/kirsten.png';
import matth from '../assets/images/matth.png';
import group from '../assets/images/group.jpg';
import r_logo from '../assets/images/r_logo.png';

export default {
    name: 'MarketingPlanCard',
    props: ['data'],
    data() {
        return {
            dena,
            rafael,
            kirsten,
            matth,
            group,
            r_logo,
        };
    },
};
</script>
<style lang="scss" scoped>
img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    width: 100%;
    opacity: 0.3;
    height: 100%;
}

.card-wrapper {
    border-radius: 0px 91.667px;
    // background: linear-gradient(0deg, rgba(33, 29, 73, 0.8) 0%, rgba(33, 29, 73, 0.8) 100%), lightgray -11.3px -255.593px / 152.264% 262.663% no-repeat;
    background: #363252;
    box-shadow: 0px 3.333px 41.667px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
}
.max-h-480 {
    @media only screen and (min-width: 1300px) {
        max-height: 480px;
    }
}
.fase-4 {
    max-width: 80% !important;
}
.fase4 {
    max-height: 350px;
    @media (max-width: 767.98px) {
        max-height: unset !important ;
    }
}

.fase5 {
    // background: linear-gradient(0deg, rgba(33, 29, 73, 0.8) 0%, rgba(33, 29, 73, 0.8) 100%), lightgray -11.3px -255.593px / 152.264% 262.663%;
    @media only screen and (min-width: 1300px) {
        max-height: unset !important;
    }
    max-height: unset !important;
}
.fase5::before {
    content: '';
    background-image: url(../assets/images/group.jpg);
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.1;
}

.r_logo {
    position: absolute;
    z-index: 9999;
    width: unset;
    top: 85%;
    left: 93%;
    opacity: 1;
    height: 40px;
    @media (max-width: 767.98px) {
        top: 90%;
        left: 80%;
    }
}

.marketingCard__card {
    background-size: 97% 105% !important;
    background-repeat: no-repeat !important;
    background-position-y: -15px !important;
    background-position-x: 15px !important;
    box-shadow: none !important;
    @media (max-width: 767.98px) {
        background-size: 102% 110% !important;
        background-position-x: -4px !important;
    }
    &--content {
        width: 85%;
        @media (max-width: 767.98px) {
            width: 0% !important;
        }
    }
}

// .fase5 {
//     background: url(../assets/images/fase5.png) !important;
//     background-size: 107.9% 110% !important;
//     background-position-x: -47px !important;
//     background-repeat: no-repeat !important;
//     @media (max-width: 767.98px) {
//         background: url(../assets/images/fase5_sm.png) !important;
//         min-height: 580px;
//     }
// }

.max-w-80 {
    max-width: 80%;
}

.card-bg {
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 100%;
    min-height: 415px;
}

.bg-1 {
    background-image: url(../assets/images/card1.png);
    background-position-x: 100%;
}
.bg-2 {
    background-image: url(../assets/images/card2.png);
    background-position-x: 95%;
}
.bg-3 {
    background-image: url(../assets/images/card3.png);
    background-position-x: 100%;
}
.bg-4 {
    background-image: url(../assets/images/card4.png);
    background-position-x: 95%;
}
</style>
