import { RepositoryFactory } from "../../repository/RepositoryFactory";
let leads = RepositoryFactory.get("leads");
const state = {
    getLoader: false,
    getErrorResponse: {},

};
const getters = {
    getLoader: (state) => state.getLoader,
    // getErrorResponses: (state) => state.getErrorResponse,



};
const actions = {
    async postLead({ commit }, payload) {
        commit("setGetLoader", true);

        try {
            const response = await leads.postLead(payload);
            console.log("form==>", payload);
            commit("setGetLoader", false);

            return {
                success: true,
            };
        } catch (error) {
            console.error("Api error:", error);

            return {
                success: false,
            };
        }
    },

};
const mutations = {
    // setError(state, payload) {
    //     state.getErrorResponse = payload;
    // },
    setGetLoader(state, payload) {
        state.getLoader = payload;
    },
}
export default {
    state,
    getters,
    actions,
    mutations,
};
