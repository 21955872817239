<template>
    <div class="bg-site-white marketing__list-item d-flex">
        <img class="pr-3 pr-lg-4" src="../assets/images/Vector.svg" alt="" />
        <p class="mb-0">{{ lists }}</p>
    </div>
</template>

<script>
export default {
    name: 'MarketingList',
    props: ['lists'],
};
</script>

<style></style>
