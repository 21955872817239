<template>
    <b-container fluid="xl">
        <div class="main-bg bg_min-size">
            <div class="position-relative">
                <div class="marketing section3 overflow-x-hidden">
                    <img
                        data-aos="fade-left"
                        data-aos-easing="linear"
                        data-aos-duration="1500"
                        class="mCampaign position-absolute"
                        src="../assets/images/marketingcampaign.png"
                        alt=""
                    />
                    <div class="extra_padding">
                        <div class="d-flex flex-column align-items-start">
                            <p data-aos="fade-zoom-in" data-aos-delay="400" data-aos-offset="0" class="text-site-white d-md-block d-none">Fase 3 van 5:</p>
                            <p data-aos="fade-right" data-aos-easing="linear" data-aos-duration="200" class="marketing__header text-site-white bg-site-info">
                                <span class="d-md-none d-block fw-light fontLight">Fase 3 van 5:</span>
                                Development fase
                            </p>
                        </div>
                        <div class="d-flex flex-column marketing__list">
                            <div
                                v-for="(item, index) in marketingPlanData.lists"
                                :key="index + 7"
                                class="d-flex"
                                data-aos="fade-right"
                                data-aos-easing="linear"
                                data-aos-duration="200"
                            >
                                <MarketingList :lists="item.text" />
                            </div>
                        </div>
                        <MarketingPlanCard class="d-none d-md-flex" :data="marketingPlanData" data-aos="zoom-in" />
                        <MarketingPlanCardMobile class="d-flex d-md-none" :data="marketingPlanData" data-aos="zoom-in" />
                    </div>
                </div>
            </div>
            <div class="position-relative">
                <div class="marketing section4">
                    <img
                        data-aos="fade-right"
                        data-aos-easing="linear"
                        data-aos-duration="1500"
                        class="mDelivery position-absolute"
                        src="../assets/images/delivery.png"
                        alt=""
                    />
                    <div class="extra_padding">
                        <div class="d-flex flex-column align-items-start align-items-lg-end">
                            <p data-aos="fade-zoom-in" data-aos-delay="400" data-aos-offset="0" class="text-site-primary d-md-block d-none">Fase 4 van 5:</p>
                            <p data-aos="fade-left" data-aos-easing="linear" data-aos-duration="200" class="marketing__header text-site-white bg-site-info">
                                <span class="d-md-none d-block fw-light fontLight">Fase 4 van 5:</span>
                                Activerings fase
                            </p>
                        </div>
                        <div class="d-flex flex-column marketing__list align-items-end">
                            <div
                                v-for="(item, index) in marketingAdsData.lists.slice(0, marketingAdsData.lists.length - 1)"
                                :key="index + 6"
                                class="d-flex list"
                                data-aos="fade-left"
                                data-aos-easing="linear"
                                data-aos-duration="200"
                            >
                                <MarketingList :lists="item.text" />
                            </div>
                            <div
                                data-aos="fade-left"
                                data-aos-easing="linear"
                                data-aos-duration="200"
                                class="bg-site-info marketing__list-item d-flex align-self-stretch align-self-md-end flex-column"
                            >
                                <div class="d-flex align-items-center mt-4 w-100">
                                    <img class="pr-3 pr-lg-4" src="../assets/images/email 2.svg" alt="" />
                                    <p class="mb-0">E-mail</p>
                                </div>
                                <div class="d-flex align-items-center mt-4 w-100">
                                    <img class="pr-3 pr-lg-4" src="../assets/images/user network.svg" alt="" />
                                    <p class="mb-0">Social media</p>
                                </div>
                                <div class="d-flex align-items-center mt-4 w-100">
                                    <img class="pr-3 pr-lg-4" src="../assets/images/window section.svg" alt="" />
                                    <p class="mb-0">Native/banner</p>
                                </div>
                                <div class="d-flex align-items-center mt-4 w-100">
                                    <img class="pr-3 pr-lg-4" src="../assets/images/link.svg" alt="" />
                                    <p class="mb-0">Text link</p>
                                </div>
                            </div>
                            <div
                                v-for="(item, index) in marketingAdsData.lists.slice(2, marketingAdsData.lists.length)"
                                :key="index + 5"
                                class="d-flex"
                                data-aos="fade-left"
                                data-aos-easing="linear"
                                data-aos-duration="200"
                            >
                                <MarketingList :lists="item.text" />
                            </div>
                        </div>
                        <img class="position-absolute layer" src="../assets/images/headerLayer.svg" alt="" width="100%" />
                        <MarketingPlanCard class="pt-3 d-none d-md-flex" :data="marketingAdsData" data-aos="zoom-in" />
                        <MarketingPlanCardMobile class="pt-3 d-block d-md-none" :data="marketingAdsData" data-aos="zoom-in" />
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
import MarketingList from '../components/MarketingList.vue';
import MarketingPlanCard from '../components/MarketingPlanCard.vue';
import MarketingPlanCardMobile from '../components/MarketingPlanCardMobile.vue';

export default {
    name: 'MarketingCampaign',
    components: {
        MarketingList,
        MarketingPlanCard,
        MarketingPlanCardMobile,
    },
    data() {
        return {
            marketingPlanData: {
                lists: [
                    {
                        text: 'Ontvangen feedback opdrachtgever op design',
                    },
                    {
                        text: 'Feedback opdrachtgever verwerken in design',
                    },
                    {
                        text: 'Akkoord van opdrachtgever opdrachtgever op design',
                    },
                    {
                        text: 'Onze developers gaan aan de slag: design wordt een werkende pagina',
                    },
                ],
                number: 3,
                title: 'Oplevering en presentatie:',
                heading: 'Conversie Marketing Campagne',
                description: 'De concreet werkende campagne.Landingspagina inclusief promotiemateriaal.',
            },
            marketingAdsData: {
                lists: [
                    {
                        text: 'Live zetten campagne binnen Re:publish platform',
                    },
                    {
                        text: 'Activeren online traffic',
                    },
                    {
                        text: 'Jouw campagne krijgt nu bezoekers',
                    },
                ],
                number: 4,
                title: 'Oplevering:',
                heading: 'Lancering Conversie',
                heading1: 'Marketing Campagne',
            },
        };
    },
};
</script>
