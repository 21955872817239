<template>
    <b-container fluid="xl">
        <div class="main-bg">
            <div class="marketing section5 position-relative">
                <div class="extra_padding">
                    <div class="d-flex flex-column align-items-start">
                        <img
                            class="mPromotion d-md-none d-flex"
                            data-aos="fade-left"
                            data-aos-easing="linear"
                            data-aos-duration="200"
                            src="../assets/images/promotion.png"
                            alt=""
                        />
                        <p data-aos="fade-zoom-in" data-aos-delay="400" data-aos-offset="0" class="text-site-white d-md-block d-none">Fase 5 van 5:</p>
                        <p data-aos="fade-right" data-aos-easing="linear" data-aos-duration="200" class="marketing__header text-site-white bg-site-info">
                            <span class="d-md-none d-block fw-light fontLight">Fase 5 van 5:</span>
                            Promotie fase
                        </p>
                    </div>
                    <PromotionCard />
                    <div>
                        <img class="mPromotion d-none d-md-flex" src="../assets/images/promotion.png" alt="" />
                        <MarketingPlanCard class="mt-5 d-none d-md-flex" :data="PromoFaseData" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1100" />
                        <MarketingPlanCardMobile
                            class="mt-5 d-flex d-md-none"
                            :data="PromoFaseData"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="1100"
                        />
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
import MarketingPlanCard from '../components/MarketingPlanCard.vue';
import PromotionCard from '../components/PromotionCard.vue';
import MarketingPlanCardMobile from './MarketingPlanCardMobile.vue';
export default {
    name: 'PromotionPhase',
    components: {
        PromotionCard,
        MarketingPlanCard,
        MarketingPlanCardMobile,
    },
    data() {
        return {
            PromoFaseData: {
                number: 5,
                title: 'Oplevering:',
                heading: 'Levering gegevens potentiële klant',
                description: 'De geworven potentiële klant is klaar om benaderd te worden met als doel klant te worden.',
                listItem: [
                    {
                        text: 'Telemarketing',
                    },
                    {
                        text: 'Email marketing',
                    },
                    {
                        text: 'Ledenwerving',
                    },
                    {
                        text: 'Direct online sale',
                    },
                    {
                        text: 'Directe afspraak',
                    },
                ],
            },
        };
    },
};
</script>

<style></style>
