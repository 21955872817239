import axios from "axios";
const baseDomain = "https://us-central1-republish-platform.cloudfunctions.net/sendEmail";
const baseURL = `${baseDomain}/`;
let axiosObj;
axiosObj = axios.create({
    baseURL,
    headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        // Authorization: `Basic MTg1OmFiODIyMWQ0YTMxNzBkODk1NDI4ODA0NTlhYmY3OTgxN2FlMzY3YzI=`,
    },
});
export default axiosObj;
