<template>
    <div>
        <div class="introduction main-bg position-relative mt-5 pt-5 overflow-x-hidden">
            <div class="extra_padding">
                <div class="text-center">
                    <img data-aos="fade-down" data-aos-duration="1000" class="introduction__logo mb-md-3 mb-3" src="../assets/images/republish-logo.svg" alt="" />
                    <p data-aos="fade-up" data-aos-duration="1000" class="introduction__logo-text text-site-white mb-2">Conversie Marketing</p>
                    <img data-aos="fade-down" data-aos-duration="1000" src="../assets/images/headerArrow.svg" alt="" class="mt-2 mb-5" />
                </div>
                <IntroCard />
                <img class="introduction__logo__card__layers" src="../assets/images/headerLayer.svg" alt="" width="100%" />

                <div class="position-relative">
                    <!-- <svg
            data-aos="zoom-in"
            data-aos-easing="linear"
            data-aos-duration="500"
            class="d-flex mx-auto mt-3"
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
          >
            <circle
              cx="8.87836"
              cy="8.87836"
              r="8.87836"
              transform="matrix(-1 0 0 1 18.423 0)"
              fill="#211D4E"
            />
          </svg>
          <img src="../assets/images/roadMap.svg" class="roadMap" /> -->
                    <MarketingPlan />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IntroCard from '@/components/IntroCard.vue';
import MarketingPlan from './MarketingPlan.vue';
export default {
    name: 'Introduction',
    components: {
        IntroCard,
        MarketingPlan,
    },
};
</script>
<style lang="scss">
// .roadMap{
// background-image: url(../assets/images/roadMap.svg);
// background-repeat: no-repeat;
// }
</style>
