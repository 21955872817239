<template>
    <div class="mt-0 mt-md-5">
        <b-row cols="1" cols-md="2" class="w-100 mx-auto pt-3 pt-md-0">
            <b-col class="pb-lg-5 pr-xl-5 position-relative mb-md-5 mb-4 mb-lg-0" order="1">
                <div data-aos="fade-right" data-aos-duration="700" class="promotion__card d-flex flex-column justify-content-md-end mb-lg-0 mb-md-0 bg-site-white">
                    <div>
                        <p
                            class="text-site-primary font-weight-bold mb-0 rounded-circle promotion__card-icon mr-lg-auto d-flex justify-content-center align-items-center"
                        >
                            1
                        </p>
                    </div>
                    <h1 class="font-family-bold promotion__card-title mb-0">Traffic</h1>
                    <p class="promotion__card-text mb-0">We genereren bereik onder de gewenste consumenten</p>
                </div>
                <img src="../assets/images/promotionArrow.svg" class="bg-site-primary rounded-circle promotion__arrow" alt="" />
            </b-col>
            <b-col class="pb-lg-5 pl-xl-5 mb-md-5 mb-4 mb-lg-0 position-relative" order="2">
                <img src="../assets/images/promotionArrow.svg" class="bg-site-primary rounded-circle promotion__arrow d-md-none d-flex" alt="" />
                <div data-aos="fade-left" data-aos-duration="700" class="promotion__card bg-site-white text-left d-flex flex-column justify-content-end text-md-right">
                    <div>
                        <p
                            class="text-site-primary mb-0 font-weight-bold rounded-circle promotion__card-icon ml-md-auto d-flex justify-content-center align-items-center"
                        >
                            2
                        </p>
                    </div>
                    <h1 class="font-family-bold promotion__card-title mb-0">Click</h1>
                    <p class="promotion__card-text mb-0">Yes, een consument toont interesse door te klikken op de advertentie of email</p>
                </div>
            </b-col>
            <b-col class="pr-xl-5 mb-md-5 mb-lg-0 position-relative" order-md="3" order="4">
                <img src="../assets/images/promotionArrow.svg" class="bg-site-primary rounded-circle promotion__arrow d-none" alt="" />
                <div
                    data-aos="fade-right"
                    data-aos-duration="700"
                    class="promotion__card mb-md-0 d-flex flex-column justify-content-md-end mb-lg-0 mb-md-0 bg-site-white"
                >
                    <div>
                        <p
                            class="text-site-primary font-weight-bold mb-0 promotion__card-icon rounded-circle text-right text-md-left d-flex justify-content-center align-items-center"
                        >
                            4
                        </p>
                    </div>
                    <h1 class="font-family-bold promotion__card-title mb-0">Optimalisatie</h1>
                    <p class="promotion__card-text mb-0">We analyseren en optimaliseren de campagne voor nog betere resultaat</p>
                </div>
            </b-col>
            <b-col class="pl-xl-5 mb-md-5 mb-4 mb-lg-0 position-relative" order-md="4" order="3">
                <img src="../assets/images/promotionArrow.svg" class="bg-site-primary rounded-circle promotion__arrow d-md-none d-flex" alt="" />
                <div data-aos="fade-left" data-aos-duration="700" class="promotion__card bg-site-white d-flex flex-column justify-content-end text-left text-md-right">
                    <div>
                        <p
                            class="text-site-primary mb-0 rounded-circle font-weight-bold promotion__card-icon ml-md-auto d-flex justify-content-center align-items-center"
                        >
                            3
                        </p>
                    </div>
                    <h1 class="font-family-bold promotion__card-title mb-0">Conversie</h1>
                    <p class="promotion__card-text mb-0">Gefeliciteerd, een potentiële klant laat zijn gegevens achter voor opvolging</p>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'promotionCard',
};
</script>

<style>
.font-family-bold {
    font-family: 'camptonBold' !important;
}
</style>
