<template>
    <div class="position-relative">
        <img data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1500" class="position-absolute mPlan" src="../assets/images/marketingplan.png" alt="" />

        <div class="marketing section1">
            <div class="d-flex flex-column align-items-start">
                <p data-aos="fade-zoom-in" data-aos-delay="400" data-aos-offset="0" class="text-site-primary d-md-block d-none">Fase 1 van 5:</p>
                <p data-aos="fade-right" data-aos-easing="linear" data-aos-duration="200" class="marketing__header text-site-white bg-site-info mb-0">
                    <span class="d-md-none d-block fw-light fontLight">Fase 1 van 5:</span>
                    Opstart fase
                </p>
            </div>
            <div class="d-flex flex-column marketing__list">
                <div v-for="(item, index) in marketingPlanData.lists" :key="index" class="d-flex" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="200">
                    <MarketingList :lists="item.text" />
                </div>
            </div>
            <MarketingPlanCard class="d-none d-md-flex" :data="marketingPlanData" data-aos="zoom-in" />
            <MarketingPlanCardMobile class="d-flex d-md-none" :data="marketingPlanData" data-aos="zoom-in" />
        </div>
    </div>
</template>

<script>
import MarketingList from '../components/MarketingList.vue';
import MarketingPlanCard from '../components/MarketingPlanCard.vue';
import MarketingPlanCardMobile from '../components/MarketingPlanCardMobile.vue';

export default {
    name: 'MarketingPlan',
    components: {
        MarketingList,
        MarketingPlanCard,
        MarketingPlanCardMobile,
    },
    data() {
        return {
            marketingPlanData: {
                lists: [
                    {
                        text: 'Kennismaking',
                    },
                    {
                        text: 'Briefing: wat zijn jouw wensen, behoeften en doelstellingen?',
                    },
                    {
                        text: 'Vertaling: hoe kunnen wij je helpen deze te behalen',
                    },
                ],
                number: 1,
                title: 'Oplevering en presentatie:',
                heading: 'Conversie Marketing Plan',
                description: 'Een concreet uitgewerkt plan dat beschrijft op welke manier we jouw doelstellingen gaan behalen.',
            },
        };
    },
};
</script>
