<template>
    <b-container fluid="xl">
        <div class="d-flex justify-content-center position-relative">
            <div class="px-2">
                <div
                    data-aos="zoom-in"
                    data-aos-easing="linear"
                    data-aos-duration="500"
                    class="contact__card bg-site-white d-flex flex-column justify-content-center align-items-center"
                >
                    <h1 data-aos="fade-zoom-in" data-aos-delay="700" data-aos-offset="0" class="contact__card-text text-site-primary text-center pt-2">
                        Klaar voor groei?
                    </h1>
                    <h2 data-aos="fade-zoom-in" data-aos-delay="700" data-aos-offset="0" class="text-site-primary text-center contact__card-font font-weight-bold">
                        Ervaar de kracht van conversie marketing
                    </h2>
                    <h4 data-aos="fade-zoom-in" data-aos-delay="700" data-aos-offset="0" class="text-site-primary pt-2 text-center contact__card-font font-weight-bold">
                        Laat je gegevens achter en wij nemen zsm contact met je op
                    </h4>
                    <div data-aos="zoom-in" class="map-col-2 banner-text-blk-1 col-6">
                        <div class="form-main contact_form">
                            <div class="form-body">
                                <div class="form-holder">
                                    <div class="form-content">
                                        <div class="form-items">
                                            <b-form @submit.prevent="onSubmit" class="requires-validation" novalidate>
                                                <b-form-checkbox v-model="bootCheck" :style="{ display: 'none' }"></b-form-checkbox>

                                                <b-form-input
                                                    class="form-control bvRenee"
                                                    v-model="form.firstname"
                                                    :state="FnameValidationCheck"
                                                    placeholder="bv. Renee"
                                                    required
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    <small class="text-danger" v-if="firstNameValid">Voornaam niet correct</small>
                                                </div>
                                                <b-form-input
                                                    v-model="form.lastname"
                                                    :state="LnameValidationCheck"
                                                    class="form-control bvBlanken"
                                                    placeholder="bv. Blankenburgh"
                                                    required
                                                ></b-form-input>
                                                <div class="invalid-feedback"><small class="text-danger" v-if="lastNameValid">Achternaam niet correct</small></div>
                                                <b-form-input
                                                    v-model="form.email"
                                                    :state="emailValidationCheck"
                                                    class="form-control bvEmail"
                                                    placeholder="bv. info@republish.nl"
                                                    required
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    <small class="text-danger" v-if="emailValid">E-mailadres niet correct</small>
                                                </div>
                                                <b-form-input
                                                    id="form_phone"
                                                    v-model="form.phone_number"
                                                    :state="phoneValidationCheck"
                                                    class="form-control bvPhone"
                                                    placeholder="bv. 0612345678"
                                                    required
                                                ></b-form-input>
                                                <div class="invalid-feedback"><small class="text-danger" v-if="phoneValid">Telefoonnummer niet correct</small></div>
                                                <div class="form__main-text-area">
                                                    <textarea
                                                        v-model="form.comment"
                                                        placeholder="Wat kunnen wij voor je betekenen? (optioneel)"
                                                        cols="30"
                                                        rows="10"
                                                        class="optionalField"
                                                    ></textarea>
                                                </div>
                                                <div class="form-button">
                                                    <button :disabled="getLoader" type="submit" class="btn btn-primary bg-site-orange submit">
                                                        <b-spinner v-if="getLoader" class="text-center" />
                                                        <span v-else class="contactMe">Neem contact met mij op</span>
                                                        <!-- <div id="spinner" class="loader" style="display: none">
                                                            <i class="fa fa-circle-o-notch fa-spin" style="margin-right: 3px"></i>
                                                            Indienen...
                                                        </div> -->
                                                    </button>
                                                    <div>
                                                        <!-- <b-button @click="$bvToast.show('my-toast')">Show toast</b-button> -->

                                                        <b-toast id="my-toast" variant="" solid>
                                                            <template #toast-title>
                                                                <div class="d-flex flex-grow-1 align-items-baseline"></div>
                                                            </template>
                                                            Bedankt voor het versturen van uw gegevens.
                                                            <br />
                                                            Er wordt zo spoedig mogelijk per email en/of telefonisch contact met u opgenomen.
                                                        </b-toast>
                                                    </div>
                                                </div>
                                                <div class="text col-12">
                                                    <p>
                                                        <span class="respectPrivacy">We respecteren je privacy</span>
                                                        <img class="form-lock-img ml-1" src="../assets/images/lock_03.png" alt="slotje" />
                                                    </p>
                                                </div>
                                            </b-form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <button data-aos="zoom-in" data-aos-duration="900" class="contact__card-btn bg-site-orange text-white border-0">Neem nu contact op</button> -->
                </div>
            </div>
            <img class="contact__card-layer" src="../assets/images/Layer_2.svg" alt="" width="100%" />
        </div>
    </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'IntroCard',
    data() {
        return {
            submited: false,
            bootCheck: false,
            firstNameValid: false,
            lastNameValid: false,
            emailValid: false,
            phoneValid: false,

            validationCheck: true,
            form: {
                // language: "nl_NL",
                firstname: '',
                lastname: '',
                email: '',
                phone_number: '',
                comment: '',
                // site_custom_url: "https://verisure.verbeterthuis.nl/beveiligingcheck",
                // site_custom_name: "af_republish_2024",
            },
        };
    },
    methods: {
        ...mapActions(['postLead']),
        async onSubmit() {
            this.firstNameValid = !this.FnameValidationCheck;
            this.lastNameValid = !this.LnameValidationCheck;
            this.emailValid = !this.emailValidationCheck;
            this.phoneValid = !this.phoneValidationCheck;
            if (!this.firstNameValid && !this.lastNameValid && !this.emailValid && !this.phoneValid) {
                const phoneNumberWithoutPunctuation = this.form.phone_number.replace(/[\s-]/g, '').replace(/^00(\d+)$/, '$1');
                let resp = await this.postLead({ ...this.form, phone_number: phoneNumberWithoutPunctuation });
                // this.$router.push('my-toast');
                console.log('form=>', this.form);
                if (resp.success == true) {
                    this.$bvToast.show('my-toast');
                    this.submited = true;
                    this.getLoader = false;
                    this.form.firstname = '';
                    this.form.lastname = '';
                    this.form.email = '';
                    this.form.phone_number = '';
                    this.form.comment = '';
                }
            }
        },
    },
    computed: {
        ...mapGetters(['getLoader']),

        FnameValidationCheck() {
            this.firstNameValid = false;
            if (this.form.firstname == '') {
                this.validationCheck = false;
                return null;
            }
            let nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
            if (this.form.firstname.match(nameRegex)) {
                this.validationCheck = true;
                return true;
            } else {
                this.validationCheck = false;
                return false;
            }
        },

        LnameValidationCheck() {
            this.lastNameValid = false;
            if (this.form.lastname == '') {
                this.validationCheck = false;
                return null;
            }
            let nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
            if (this.form.lastname.match(nameRegex)) {
                this.validationCheck = true;
                return true;
            } else {
                this.validationCheck = false;
                return false;
            }
        },
        phoneValidationCheck() {
            this.phoneValid = false;
            if (this.form.phone_number == '') {
                this.validationCheck = false;
                return null;
            }

            let phoneRegex = /^(0|\+31|0031|00 31)([-\s]?\d[-\s]?){9}$/;
            if (this.form.phone_number == '0') {
                this.validationCheck = false;
                return true;
            } else if (this.form.phone_number.match(phoneRegex)) {
                this.validationCheck = true;
                return true;
            } else {
                this.validationCheck = false;
                return false;
            }
        },
        emailValidationCheck() {
            this.emailValid = false;
            if (this.form.email == '') {
                this.validationCheck = false;
                return null;
            }

            if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/.test(this.form.email)) {
                this.validationCheck = true;
                return true;
            } else {
                this.validationCheck = false;
                return false;
            }
        },
    },
};
</script>

<style></style>
