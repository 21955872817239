<template>
    <b-container fluid="xl">
        <div class="marketing section2 position-relative">
            <img
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="1200"
                class="position-absolute mDesign"
                src="../assets/images/marketingdesign.png"
                alt=""
            />
            <div class="extra_padding">
                <div class="d-flex flex-column align-items-start align-items-lg-end">
                    <p data-aos="fade-zoom-in" data-aos-delay="400" data-aos-offset="0" class="text-site-white d-md-block d-none">Fase 2 van 5:</p>
                    <p data-aos="fade-left" data-aos-easing="linear" data-aos-duration="200" class="marketing__header text-site-white bg-site-info">
                        <span class="d-md-none d-block fw-light fontLight">Fase 2 van 5:</span>
                        Creatieve fase
                    </p>
                </div>
                <img class="layers2" src="../assets/images/Layer_2.svg" alt="" width="100%" />
                <div class="d-flex flex-column marketing__list align-items-lg-end">
                    <div
                        v-for="(item, index) in marketingDesignData.lists"
                        :key="index"
                        class="d-flex"
                        data-aos="fade-left"
                        data-aos-easing="linear"
                        data-aos-duration="200"
                    >
                        <MarketingList :lists="item.text" />
                    </div>
                </div>
                <MarketingPlanCard class="mt-5 d-none d-md-flex" :data="marketingDesignData" data-aos="zoom-in" />
                <MarketingPlanCardMobile class="mt-5 d-flex d-md-none" :data="marketingDesignData" data-aos="zoom-in" />
            </div>
        </div>
    </b-container>
</template>

<script>
import MarketingList from '../components/MarketingList.vue';
import MarketingPlanCard from '../components/MarketingPlanCard.vue';
import MarketingPlanCardMobile from '../components/MarketingPlanCardMobile.vue';

export default {
    name: 'MarketingDesign',
    components: {
        MarketingList,
        MarketingPlanCard,
        MarketingPlanCardMobile,
    },
    data() {
        return {
            marketingDesignData: {
                lists: [
                    {
                        text: 'Brainstorm campagne opzet',
                    },
                    {
                        text: 'Opstellen design briefing',
                    },
                    {
                        text: 'Creatie design',
                    },
                    {
                        text: 'Interne oplevering design',
                    },
                    {
                        text: 'Interne evaluatie en optimalisatie design',
                    },
                ],
                number: 2,
                title: 'Oplevering en presentatie:',
                heading: 'Conversie Marketing Design',
                description: 'Het concreet uitgewerkte design  met jouw toekomstige campagne.',
            },
        };
    },
};
</script>

<style></style>
