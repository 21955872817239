<template>
    <div class="mt-5">
        <div class="d-flex justify-content-center">
            <div
                class="marketingCard__card d-flex flex-column justify-content-start align-items-start"
                :class="data.number == 1 ? 'fase1' : data.number == 2 ? 'fase2' : data.number == 3 ? 'fase3' : data.number == 4 ? 'fase4' : 'fase5 no-repeat'"
                :style="data.number == 5 ? 'background-repeat: no-repeat !important' : ''"
            >
                <div
                    class="marketingCard__card--content mt-5 mx-auto"
                    :class="data.number === 1 || data.number === 2 || data.number === 3 ? 'my-3' : data.number === 5 ? ' my-4 my-md-5 py-md-5' : ''"
                >
                    <p class="text-site-white mt-5 d-none d-md-flex" :class="data.number === 4 ? 'pt-5' : ''">Einde fase {{ data.number }}</p>
                    <h1 class="marketingCard__card-title text-site-white text-start p3-5">
                        {{ data.title }}
                    </h1>
                    <hr class="marketingCard__card-line bg-site-orange w-50 my-lg-4 mx-0" />
                    <p class="marketingCard__card-text text-site-white">
                        <span class="marketingCard__card-text-heading">{{ data.heading }}</span>
                        <br />
                        <span class="font-weight-bold" v-if="data.heading1">{{ data.heading1 }}</span>
                        {{ data.description }}
                    </p>
                    <ul class="text-site-white px-4 px-md-0" v-if="data.listItem">
                        <li class="marketingCard__card-text" v-for="(item, index) in data.listItem" :key="index">
                            {{ item.text }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MarketingPlanCard',
    props: ['data'],
};
</script>
<style lang="scss" scoped>
.fase1 {
    background: url(../assets/images/dena.png) !important;
    @media (max-width: 767.98px) {
        background: url(../assets/images/fase1_sm.png) !important;
        background-size: 100% 100% !important;
        min-height: 490px;
    }
}
.fase2 {
    background: url(../assets/images/rafael.png) !important;
    @media (max-width: 767.98px) {
        background: url(../assets/images/fase2_sm.png) !important;
        background-size: 100% 100% !important;
        min-height: 485px;
    }
}
.fase3 {
    background: url(../assets/images/kirsten.png) !important;
    @media (max-width: 767.98px) {
        background: url(../assets/images/fase3_sm.png) !important;
        background-size: 100% 100% !important;
        min-height: 520px;
    }
}
.fase4 {
    background: url(../assets/images/matth.png) !important;
    min-height: 425px;
    @media (max-width: 767.98px) {
        background: url(../assets/images/fase4_sm.png) !important;
        background-size: 100% 100% !important;
        min-height: 410px;
    }
}

.marketingCard__card {
    // background-size: 97% 105% !important;
    background-repeat: no-repeat !important;
    // background-position-y: -15px !important;
    // background-position-x: 15px !important;
    box-shadow: none !important;
    @media (max-width: 767.98px) {
        // background-size: 102% 110% !important;
        // background-position-x: -4px !important;
        // border: 1px solid #837d7b50 !important;
    }
    &--content {
        width: 85%;
        @media (max-width: 767.98px) {
            width: 90%;
        }
    }
}

.fase5 {
    background: url(../assets/images/group.jpg) !important;
    background-size: 107.9% 110% !important;
    background-position-x: -47px !important;
    background-repeat: no-repeat !important;
    @media (max-width: 767.98px) {
        background: url(../assets/images/fase5_sm.png) !important;
        background-size: 100% 100% !important;
        min-height: 587px;
    }
}
</style>
